import MUIRichTextEditor from 'mui-rte'
import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'

export default function ProductPreviewPart({ project, projectGalleryImages }) {

  const [imagesForGallery, setImagesForGallery] = useState([])

  useEffect(() => {
    setImagesForGallery(projectGalleryImages.map(projectGalleryImage => ({ original: projectGalleryImage.image.full.url, thumbnail: projectGalleryImage.image.thumbnail.url, originalAlt: projectGalleryImage.image_alt, thumbnailAlt: projectGalleryImage.image_alt })))
  }, [projectGalleryImages])

  return (
    <React.Fragment>
      <ImageGallery items={imagesForGallery} lazyLoad />
      <div className="grid grid-cols-2 gap-4">
        <MUIRichTextEditor defaultValue={project.description} readOnly controls={[]} />
        <MUIRichTextEditor defaultValue={project.description2} readOnly controls={[]} />
      </div>
    </React.Fragment>
  )
}