import React from 'react'

export default function MetalDivision() {
  return (
    <div className="text-black">
      <p className="mb-4">
        As the region's leading building envelope specialist, on a daily basis CTI is capable of producing up to 650 high-quality, custom-made, unitized wall panels.
        <br />
        <br />
        Our highly experienced team and end-to-end manufacturing capabilities guarantees the fulfilment of all your building wrapping requirements from design to build. CTI provides a wide array of materials and services in the metal division; from aluminium and steel, to glass processing and coating.
      </p>

      <p>
        No project is beyond our scope, and we have built our reputation on service excellence.
      </p>
    </div>
  )
}