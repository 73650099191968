import React from 'react'

export default function CladdingDivision() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 text-black gap-8">
      <div className="w-full">
        <p>
          This division supports the primary activity of the fabrication of high performance aluminium facades. The Cladding Division employs the most modern and advanced CNC machinery availabel to date; from cutting equipment, roll-bending equipment, as well as all other important facilities necessary for the fabrication of composite cladding panels.
          <br />
          <br />
          CTI's Cladding Division is staffed by a team of highly-skilled operators, supervisors, and managers with a strong background in engineering - specifically in the production of high quality composite and non-composite cladding panels.
        </p>
      </div>

      <div className="w-full">
        <p>
          CTI is always up-to-date and consistenyl follows current international standards to meet the requirements of high-quality and performance sets by modern architectural design.
        </p>
      </div>
    </div>
  )
}