import React, { useState } from 'react'
import AluminiumDivision from './Aluminium'
import GlassDivision from './Glass'
import MetalDivision from './Metal'
import CladdingDivision from './Cladding'
import PowderDivision from './Powder'

export default function Divisions() {
  const [activeTab, setActiveTab] = useState('Aluminium')

  return (
    <>
      <div className="flex mb-16 w-full justify-between overflow-auto lg:overflow-visible gap-4 md:gap-0">
        <button
          className={`btn btn--tab dinamic-padding whitespace-nowrap text-center lg:text-left ${activeTab === 'Aluminium' ? 'active' : ''}`}
          onClick={() => setActiveTab('Aluminium')}
        >
          Aluminium Division
        </button>

        <button
          className={`btn btn--tab dinamic-padding whitespace-nowrap text-center lg:text-left ${activeTab === 'Glass' ? 'active' : ''}`}
          onClick={() => setActiveTab('Glass')}
        >
          Glass Division
        </button>

        <button
          className={`btn btn--tab dinamic-padding whitespace-nowrap text-center lg:text-left ${activeTab === 'Metal' ? 'active' : ''}`}
          onClick={() => setActiveTab('Metal')}
        >
          Metal Division
        </button>

        <button
          className={`btn btn--tab dinamic-padding whitespace-nowrap text-center lg:text-left ${activeTab === 'Cladding' ? 'active' : ''}`}
          onClick={() => setActiveTab('Cladding')}
        >
          Cladding Division
        </button>

        <button
          className={`btn btn--tab dinamic-padding whitespace-nowrap text-center lg:text-left ${activeTab === 'Powder' ? 'active' : ''}`}
          onClick={() => setActiveTab('Powder')}
        >
          Powder coating
        </button>
      </div>

      {activeTab === 'Aluminium' && <AluminiumDivision />}
      {activeTab === 'Glass' && <GlassDivision />}
      {activeTab === 'Metal' && <MetalDivision />}
      {activeTab === 'Cladding' && <CladdingDivision />}
      {activeTab === 'Powder' && <PowderDivision />}
    </>
  )
}