import React, { useState } from 'react'
import axiosService from '../../common/AxiosService'

export default function Gallery({ galleryImages }) {
  const [images, setImages] = useState(galleryImages)
  const [morePicturesLoaded, setMorePicturesLoaded] = useState(false)

  const loadMorePictures = () => {
    axiosService.get('/gallery.json')
    .then(galleryImages => {
      console.log(galleryImages)
      setMorePicturesLoaded(true)
      setImages(galleryImages)
    })
  }

  return (
    <React.Fragment>
      <div className="flex flex-wrap mb-20 -mx-4">
        {
          images.map((image, index) => (
            <div key={`${image.cladtech.url}-${index}`} className="mb-8 px-4 w-full lg:w-1/2">
              <img src={image.cladtech.url} />
            </div>
          ))
        }
      </div>
      {!morePicturesLoaded && <div className="flex justify-center">
        <button className="btn btn--primary" onClick={loadMorePictures}>Load more pictures</button>
      </div>}
    </React.Fragment>
  )
}