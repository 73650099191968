import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

export default function Map({
  cord1,
  cord2,
}) {
  const position = [cord1, cord2]

  return (
    <div style={{ height: 340 }}>
      <MapContainer
        center={position}
        scrollWheelZoom={false}
        zoom={9}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  )
}
