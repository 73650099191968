import React from 'react'

export default function ContactForm() {
  return (
    <form>
      <div className="flex flex-wrap mb-8 lg:-mx-4">
        <div className="lg:px-4 w-full lg:w-5/12">
          <div className="mb-8 relative">
            <label className="label-over-field">Name</label>
            <input type="text" className="input" name="name" />
          </div>

          <div className="mb-8 relative">
            <label className="label-over-field">Email</label>
            <input type="text" className="input" name="email" />
          </div>

          <div className="mb-8 relative">
            <label className="label-over-field">Phone Number</label>
            <input type="text" className="input" name="phone" />
          </div>
        </div>

        <div className="lg:px-4 w-full lg:w-7/12">
          <div className="relative">
            <label className="label-over-field">Message</label>
            <textarea className="textarea" name="message" />
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button className="btn btn--primary" type="submit">Send message</button>
      </div>
    </form>
  )

}