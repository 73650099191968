import React, { useState } from 'react'

export default function Clients({ logos }) {
  const [active, setActive] = useState(0)

  return (
    <React.Fragment>
      <h2 className="title title--line flex mb-28">
        <span className="text-center playfair-display-bold text-shadow">
          Our prestigious clients
        </span>
      </h2>
      <div className="flex flex-wrap -mx-4">
        { logos.map((logo, index) => (
          <div key={logo.id} className="mb-8 px-4 w-1/3 lg:w-2/12">
            <button className={`toggle-icon block ${active === index && 'active'}`} onClick={() => setActive(index) }>
              <img src={active === index ? logo.image_selected.url : logo.image.url} />
            </button>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}
