import React, { lazy, Suspense, useEffect, useState } from 'react'
const RedSelect = lazy(() => import(/* webpackChunkName: "RedSelect" */'../../shared/RedSelect'))

export default function Filter({ regions, params }) {
  const [ongoing, setOngoing] = useState(params.ongoing || undefined)
  const [region_id, setRegionId] = useState(params.region_id)
  const [first_run, setFirstRun] = useState(true)

  const options = [{ value: undefined, label: 'All' }, { value: 'true', label: 'Ongoing' }, { value: 'false', label: 'Not Ongoing' }]

  const onOngoingChange = ({ value }) => setOngoing(value)
  const handleClick = regionId => () => setRegionId(regionId)
  const selectedOngoing = () => options.find(option => option.value === ongoing)

  useEffect(() => {
    if(!first_run) {
      const query_object = {}
      if(ongoing) query_object.ongoing = ongoing
      if(region_id) query_object.region_id = region_id
      const query_string = new URLSearchParams(query_object).toString()
      window.location = '/projects' + query_string.length ? `?${query_string}` : ''
    } else {
      setFirstRun(false)
    }
  }, [ongoing, region_id])

  return (
    <React.Fragment>
      <div className="sm:w-3/12">
        <Suspense fallback={<div></div>}>
          <RedSelect className="w-full" value={selectedOngoing()} options={options} onChange={onOngoingChange}/>
        </Suspense>
      </div>
      <div className="font-bold lg:mt-0 mt-5 sm:text-xl sm:text-left text-center text-xs">
        <button className={`mr-4 ${region_id ? 'text-cod-gray-o-50' : 'text-primary' }`} onClick={handleClick(undefined)}>All</button>
        { regions.map(region => (
          <button key={region.id} className={`mr-4 ${+region_id === region.id ? 'text-primary' : 'text-cod-gray-o-50' }`} onClick={handleClick(region.id)}>{region.name}</button>
        ))}
      </div>
    </React.Fragment>
  )
}