import React from 'react'

export default function PowderDivision() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 text-black gap-8">
      <div className="w-full">
        <p>
          Aluminium is widely used in all forms of construction and transportation because of it's strength despite being lightweight. In its raw form, aluminium has a mill finish, which oxidizes over time and leaves an uneasthetic appearence. To create durable, non-oxidixing aluminium extrustions for architectural applications, it needs to be anodized or painted with a Polyester Powder Coat (PPC) paint.
          <br />
          <br />
          Aluminium alloys are anodized to increase corrosion resistance, increase surface hardness, and allow for dyeing (coloring). Anodizing is an electrolytic passivation process used to increase the natural oxide layer on the aluminum surface. The process is called “Anodizing” because the part to be treated forms the anode electrode of an electrical circuit. This process simultaneously strengthens the aluminium and improves the appearance of the material.
        </p>
      </div>

      <div className="w-full">
        <p>
          PPC is a painting process where a dry polymer powder (thermosetting or thermoplastic) is applied to a metal component by means of electrostatic attraction. The method is similar to when a honeybee collects pollen from the stamens of a flower. This coating is then cured in an oven where it will melt and form into a "skin" with a very hard finish that is tougher than conventional paint.
          <br />
          <br />
          CTI is in possession of our very own, in-house, state-of-the-art, Anodizing & Powder Coating Automatic line. Using highly advanced technology from Germany, we have an operational capacity of processing up to 40 tons of aluminium per day. Our pioneering machinery and conveyor line monitored by qualified workers are the powerhouses behind our production's great speed and quality output.
        </p>
      </div>
    </div>
  )
}