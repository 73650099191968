import React from 'react'

export default function GlassDivision() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 text-black gap-8">
      <div className="w-full">
        <p>
          CTI's innovative manufacturing plant designates an area of approx. 11700m2 solely for glass-processing. This plant is equipped with highly advanced and automated machinery for a broad range of glass-processing requirements. All these processes are computer-controlled in order to guarantee high efficiency and quality, while reducing the manual handling of glass.
        </p>
      </div>

      <div className="w-full">
        <p>
          Each machine is equipped with automatic handling devices to maintain said quality levels. The production capacity of our Insulated Glazed Units is up to 40,000sqm per month; and our tempering capacity is up to 90,000sqm per month. CTI's glass plant also features a reverse osmosis water treatment plant that recycles 80% of the water that we use. This system helps us reduce daily water usage and provides us with quality water washes for high performance glass. Utilizing this water treatment plant allows us to conduct a smooth-running operation without having to sacrifice the integrity and resources of planet earth.</p>
      </div>
    </div>
  )
}