import React from 'react'
import AboutAluminiumImage1 from 'images/cladtech/about-aluminium-image-1.jpg'
import AboutAluminiumImage2 from 'images/cladtech/about-aluminium-image-2.jpg'

export default function AluminiumDivision() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 text-black gap-8">
      <div className="w-full">
        <img src={AboutAluminiumImage1} alt="about aluminium"/>
      </div>

      <div className="w-full">
        <p className="mb-10">We are specialists in the design, manufacture, and installation of aluminium curtain walling, windows and doors for every type of commercial and residential building.</p>
        <p className="mb-10">CTI is equipped with a complete and advanced range of modern technology in aluminium fabrication.</p>
        <p>CTI's state-of-the-art factory is designed to take mass-produce using modern techniques in order to ensure only the highest levels of accuracy and outputs while maintaining optimum costs. All design work are generated in-house and all installations are handled by CTI's Contracts Department; including all labour and project management on site. This integrated system of personnel and plant maximizes our capacity to deliver even the most complex orders in a timely and efficient manner - all while following the standards and specifications of the client.</p>
      </div>

      <div className="w-full">
        <p>Curtain walling techniques have been used to cover the structural skeletons of high-rise buildings for over a hundred years. Up to around 1960, (galvanized) steel was the most commonly used material prior to the arrival of mass-produced aluminium extrusions in the USA. The most prominent pioneer of aluminium and glass curtain wall technology was the American architect Ludwig Mies Van Der Rohe; he was the first to recognize the colossal advantages of creating a building envelope using these materials. Van Der Rohe described curtain walls as "LESS IS MORE" - which brings us to where we are today. Here at CTI we are committed to the philosophy that curtain-walling is the most cost-effective way to not only clad the exterior of a building structure, but to maximise the developer's rented floor space as well.</p>
      </div>

      <div className="w-full">
        <img src={AboutAluminiumImage2} alt="about aluminium"/>
      </div>

      <div className="w-full">
        <p className="mb-10">Curtain walling is a grid of thousands of metal components with joints that are formed mechanically (not welded). The main function of a curtain wall is to protect a building's interior from air and water penetration. This design of mechanical connection allows for all kinds of differential movement, such as thermal expansion and contraction, wind loads, building movements, and seismic effects. Cladding systems are extremely complex structures because they need to be flexible and resistant while maintaining a secure and high-level of indoor comfort.<br /><br /> So how is this done?</p>
        <p className="mb-10">Curtain walls are designed on the principle of allowing air and water to partially filter into the system, and then to be rid of it through pressure-equalised drained joints. Each curtain wall panel or frame-member is designed to interlock with it's adjacent components to create a grid of great strength (while also maintaining independent movement) once attached to the underlying building structure.</p>
        <p className="mb-10">These principles acknowledge the need to provide a building envelope which is both wind-proof and rain-proof, but remains light and elegant - thereby enhancing the aesthetic appearance of the building. The curtain wall is what we mainly see when we look at a typical modern structure.</p>
        <p>The design requirments of cutting and machining of extrusions are prepared using a Cad/Cam software, which is integrated into the factory equipment and directly programmed to carry out all cutting and machining operations. When it comes to loading any particular extrusion to the production line, the operation is automated through to completion of the manufacturing sequence.</p>
      </div>

      <div className="w-full">
        <p className="mb-10">CTI's curtain walls are designed on the "Rain Screen" principle, which allows for controlled water-penetration to filter through the external seals. This system prevents water from collecting internally and causing damage to the structure. Our  curtain wall systems also incorporate the "Pressure Equalisatoin" method; which is a technique used to neutralize any localized buildup of air pressure that could force it's way into the building. This system drains water and lets out air horizontally through the mullions to the exterior of the building by the use of notches in the joint-cavities. This way, the interior seals remain uncompromised and the indoor environment is fully protected.</p>
        <p>In order to successfully manufacture and install top-of-the-line cladding systems, attention to detail is crucial. Which is why CTI employs only highly-skilled professionals and specialists to conduct every project's fabrication and installation process.</p>
      </div>
    </div>
  )
}